import moment from 'moment'
import {
  VALID_DATE_EMPTY_REGEX,
  VALID_DATE_SLASH_ZERO_REGEX,
  VALID_DATE_SLASH_SIMPLE_YYYYMD_REGEX,
  VALID_DATE_SLASH_SIMPLE_YYYYMMD_REGEX,
  VALID_DATE_SLASH_SIMPLE_YYYYMDD_REGEX
} from '@/utils/define'

// https://momentjs.com/docs/#/manipulating/add/
// Key	Shorthand
// years	y
// quarters	Q
// months	M
// weeks	w
// days	d
// hours	h
// minutes	m
// seconds	s
// milliseconds	ms

export const HHmm = ({ h = 0, m = 0 }) => {
  return moment()
    .add(h, 'hours')
    .add(m, 'minutes')
    .format('HHmm')
}

export const YYYYMMDD = ({ y = 0, M = 0, d = 0 } = {}) => {
  return moment()
    .add(y, 'years')
    .add(M, 'months')
    .add(d, 'days')
    .format('YYYYMMDD')
}

export const YYYYMMDDHHmm = ({ y = 0, M = 0, d = 0, h = 0, m = 0 }) => {
  return moment()
    .add(y, 'years')
    .add(M, 'months')
    .add(d, 'days')
    .add(h, 'hours')
    .add(m, 'minutes')
    .format('YYYYMMDDHHmm')
}

export const jpDate = ({
  YYYYMMDD = '',
  y = 0,
  M = 0,
  d = 0,
  short = true,
  weekday = false
} = {}) => {
  const dd = weekday ? '（dd）' : ''
  return (YYYYMMDD
    ? moment(YYYYMMDD, 'YYYYMMDD')
    : moment()
        .add(y, 'years')
        .add(M, 'months')
        .add(d, 'days')
  ).format(short ? `Y年M月D日${dd}` : `YYYY年MM月DD日${dd}`)
}

//↓ /が入った日付の文字列をYYYYMMDDに変換する関数。
//  moment('YYYY/M/D')のように/が入っていると実行時にwarningが発生する。
//  そのため第二引数でformatを指定してwarningが出ないようにしている。
//  正規表現で日付のformatを特定し、そのformatを引数に指定している。
export const convertVariousDateFormatToYYYYMMDD = date => {
  if (VALID_DATE_EMPTY_REGEX.test(date)) {
    //YYYYMMDDまたは空文字 例 20220601
    return date
  } else if (VALID_DATE_SLASH_ZERO_REGEX.test(date)) {
    //YYYY/MM/DD 例 2022/06/01, 2022/06/10, 2022/12/01, 2022/12/10
    return date.replace(/\//g, '')
  } else if (VALID_DATE_SLASH_SIMPLE_YYYYMD_REGEX.test(date)) {
    //YYYY/M/D 例 2022/6/1
    return moment(date, 'YYYY/M/D').format('YYYYMMDD')
  } else if (VALID_DATE_SLASH_SIMPLE_YYYYMMD_REGEX.test(date)) {
    //YYYY/MM/D 例 2022/12/1
    return moment(date, 'YYYY/MM/D').format('YYYYMMDD')
  } else if (VALID_DATE_SLASH_SIMPLE_YYYYMDD_REGEX.test(date)) {
    //YYYY/M/DD 例 2022/6/10
    return moment(date, 'YYYY/M/DD').format('YYYYMMDD')
  } else {
    return null
  }
}
